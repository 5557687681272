<template>
  <BasePageContent>
    <BasePageHeader
      :sectiontitle="Customer.uuid ? 'EDIT CUSTOMER' : ''"
      :title="Customer.uuid ? Customer.customer_name : 'New Customer'"
      :description="Customer.uuid ? Customer.email : ''"
    >
      <ul class="nk-block-tools g-1">
        <li v-if="Customer.uuid">
          <div class="drodown">
            <a
              href="#"
              class="dropdown-toggle btn btn-white btn-dim btn-outline-light"
              data-toggle="dropdown"
            >
              <em class="icon ni ni-more-h"></em
            ></a>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="link-list-opt no-bdr">
                <li>
                  <a title="Disable Customer" href="#">
                    <em class="dd-indc icon ni ni-copy"></em>
                    <span>Disable Customer</span>
                  </a>
                </li>
                <li>
                  <a
                    title="Delete Customer"
                    class="w3-text-red w3-hover-text-black"
                    href="#"
                  >
                    <em class="dd-indc icon ni ni-trash"></em>
                    <span> Delete Customer</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nk-block-tools-opt">
          <a
            class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
            title="Save Changes"
            href="#"
            @click.prevent="SaveCustomer"
          >
            <em class="icon ni ni-save"></em>
            <span>Save</span>
          </a>
        </li>
        <li class="nk-block-tools-opt">
          <a href="#" @click.prevent="OnCancel"
            ><em class="icon ni ni-cross size36"></em
          ></a>
        </li>
      </ul>
    </BasePageHeader>
    <div class="p-2 w3-white">
      <template v-if="!uuid || ((uuid || customer) && !Util.isEmpty(Customer))">
        <div v-if="fetching" class="px-3">Loading...</div>
        <form v-else :id="ID" @submit.prevent>
          <div v-if="tabbed" id="tabs" class="mb-4 py-0 w3-border-bottom">
            <button
              v-for="view in Views"
              :key="view"
              @click.prevent="SwitchView(view)"
              class="w3-button px-2 pt-3 w3-hover-transparent"
              :class="[
                ActiveView === view
                  ? 'pb-1 w3-bottombar w3-border-theme'
                  : ' pb-2',
              ]"
            >
              <i
                class="w3-text-theme w3-hover-text-theme"
                :class="ViewsIcons[view]"
              />
              <span class="w3-hide-small ml-1">{{ view }}</span>
            </button>
          </div>
          <div
            v-show="IsActiveView('Contact')"
            class="basicInfo mt-5 w3-animate-opacity"
            :class="[!Tabbed ? 'w3-border-bottom' : '']"
          >
            <h4 class="mb-5">
              <i class="mr-1" :class="ViewsIcons['Contact']" /> Contact
              Information
            </h4>
            <div class="row g-2">
              <div class="col-md-4">
                <label for="customer_name">Customer name</label>
              </div>
              <div class="col-md-8 mb-3">
                <BaseInput
                  id="customer_name"
                  v-model="Customer.customer_name"
                  hint="Name of a business or person."
                />
              </div>
            </div>
            <div class="row g-2">
              <div class="col-md-4">
                <h6 class="bold">Primary Contact</h6>
              </div>
              <div class="col-md-8 mb-2">
                <div class="row g-2">
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="firstname"
                      v-model="Customer.firstname"
                      label="First Name"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="lastname"
                      v-model="Customer.lastname"
                      label="Last Name"
                      type="text"
                    />
                  </div>
                  <div class="col-md-12 mb-2">
                    <BaseInput
                      id="email"
                      v-model="Customer.email"
                      label="Email"
                      type="email"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="mobile"
                      v-model="Customer.mobile"
                      label="Mobile"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="telephone"
                      v-model="Customer.telephone"
                      label="Telephone"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="IsActiveView('Billing')"
            class="billingInfo mt-5 w3-animate-opacity"
            :class="[!Tabbed ? 'w3-border-bottom' : '']"
          >
            <h4 class="mb-5">
              <i class="mr-1" :class="ViewsIcons['Billing']" /> Billing
              Information
            </h4>
            <div class="row g-2">
              <div class="col-md-4">
                <h6 class="bold">Billing Address</h6>
              </div>
              <div class="col-md-8 mb-2">
                <div class="row g-2">
                  <div class="col-md-12 mb-2">
                    <BaseInput
                      id="billing_address"
                      v-model="Customer.billing_address"
                      label="Street address"
                    />
                  </div>
                  <div class="col-md-12 mb-2">
                    <BaseInput
                      id="billing_city"
                      v-model="Customer.billing_city"
                      label="City"
                    />
                  </div>

                  <div class="col-md-6 mb-2">
                    <div class="form-group">
                      <label for="billing_country" class="form-label"
                        >Country
                      </label>
                      <select
                        id="billing_country"
                        v-model="Customer.billing_country"
                        @change="GetBillingStates"
                        class="form-control form-control-lg select2"
                        data-placeholder="Select country"
                        data-allow-clear="true"
                      >
                        <option
                          v-for="country in Settings._COUNTRIES"
                          :key="country.country_code"
                          :value="country.country_code"
                        >
                          {{ country.country_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2">
                    <div class="form-group">
                      <label for="billing_state" class="form-label"
                        >State
                      </label>
                      <select
                        id="billing_state"
                        v-model="Customer.billing_state"
                        class="form-control form-control-lg select2"
                        data-placeholder="Select state"
                        data-allow-clear="true"
                      >
                        <option
                          v-for="state in BillingStates"
                          :key="state.state_code"
                          :value="state.state_code"
                        >
                          {{ state.state_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="billing_postcode"
                      v-model="Customer.billing_postcode"
                      label="Postal/Zip code"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="IsActiveView('Shipping')"
            class="shippingInfo mt-5 w3-animate-opacity"
            :class="[!Tabbed ? 'w3-border-bottom' : '']"
          >
            <h4 class="mb-3">
              <i class="mr-1" :class="ViewsIcons['Shipping']" />
              Shipping Information
            </h4>
            <div class="mb-5 pl-5">
              <BaseCheckbox
                data-value="same_address"
                label="Same as Billing information"
                v-model="Customer.same_address"
              />
            </div>
            <div v-show="!Customer.same_address" class="row g-2">
              <div class="col-md-4">
                <h6 class="bold">Shipping Contact</h6>
              </div>
              <div class="col-md-8 mb-2">
                <div class="row g-2">
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="shipping_contact_firstname"
                      v-model="Customer.shipping_contact_firstname"
                      label="First Name"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="shipping_contact_lastname"
                      v-model="Customer.shipping_contact_lastname"
                      label="Last Name"
                      type="text"
                    />
                  </div>
                  <div class="col-md-12 mb-2">
                    <BaseInput
                      id="shipping_contact_email"
                      v-model="Customer.shipping_contact_email"
                      label="Email"
                      type="email"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="shipping_contact_mobile"
                      v-model="Customer.shipping_contact_telephone"
                      label="Mobile"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="shipping_contact_telephone"
                      v-model="Customer.shipping_contact_alt_telephone"
                      label="Telephone"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-show="!Customer.same_address" class="row g-2">
              <div class="col-md-4">
                <h6 class="bold">Shipping Address</h6>
              </div>
              <div class="col-md-8 mb-2">
                <div class="row g-2">
                  <div class="col-md-12 mb-2">
                    <BaseInput
                      id="shipping_address"
                      v-model="Customer.shipping_address"
                      label="Street address"
                      type="text"
                    />
                  </div>
                  <div class="col-md-12 mb-2">
                    <BaseInput
                      id="shipping_city"
                      v-model="Customer.shipping_city"
                      label="City"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <div class="form-group">
                      <label for="shipping_country" class="form-label"
                        >Country
                      </label>
                      <select
                        id="shipping_country"
                        v-model="Customer.shipping_country"
                        @change="GetShippingStates"
                        class="form-control form-control-lg select2"
                        data-placeholder="Select country"
                        data-allow-clear="true"
                      >
                        <option
                          v-for="country in Settings._COUNTRIES"
                          :key="country.country_code"
                          :value="country.country_code"
                        >
                          {{ country.country_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2">
                    <div class="form-group">
                      <label for="shipping_state" class="form-label"
                        >State
                      </label>
                      <select
                        id="shipping_state"
                        v-model="Customer.shipping_state"
                        class="form-control form-control-lg select2"
                        data-placeholder="Select state"
                        data-allow-clear="true"
                      >
                        <option
                          v-for="state in ShippingStates"
                          :key="state.state_code"
                          :value="state.state_code"
                        >
                          {{ state.state_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2">
                    <BaseInput
                      id="shipping_postcode"
                      v-model="Customer.shipping_postcode"
                      label="Postal/Zip code"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="IsActiveView('Others')"
            class="otherInfo mt-5 w3-animate-opacity"
          >
            <h4 class="mb-5">
              <i class="mr-1" :class="ViewsIcons['Others']" /> Other Information
            </h4>
            <div class="row g-2">
              <div class="col-md-4">
                <label for="fax">Fax</label>
              </div>
              <div class="col-md-8 mb-3">
                <BaseInput id="fax" v-model="Customer.fax" type="text" />
              </div>
            </div>
            <div class="row g-2">
              <div class="col-md-4">
                <label for="website">Website</label>
              </div>
              <div class="col-md-8 mb-3">
                <BaseInput
                  id="website"
                  v-model="Customer.website"
                  type="text"
                />
              </div>
            </div>
            <div class="row g-2">
              <div class="col-md-4">
                <label for="internal_note">Internal note</label>
              </div>
              <div class="col-md-8 mb-3">
                <BaseInput
                  id="internal_note"
                  v-model="Customer.internal_note"
                  type="textarea"
                  class="min-height150"
                  hint="Not entered here will not be visible to your customer"
                />
              </div>
            </div>
          </div>
        </form>
      </template>
      <div
        v-else-if="!fetching && (uuid || customer) && Util.isEmpty(Customer)"
        class="p-5 w3-center"
      >
        <img src="/images/item-not-found.png" class="mb-4" />
        <h4>Customer not found</h4>
        <router-link
          :to="{ name: 'customers' }"
          class="w3-button w3-white w3-border"
        >
          <span class="">Manage Customers</span>
        </router-link>
      </div>
    </div>
  </BasePageContent>
</template>
<script>
import { computed, onMounted, ref, toRef } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { v4 as uuidv4 } from "uuid";
import { frontendUrl, Util } from "@/helpers/utilities";
import { loadScript } from "vue-plugin-load-script";

export default {
  components: {},
  props: {
    id: {
      type: String,
    },
    uuid: {
      type: String,
    },
    customer: {
      type: Object,
    },
    tabbed: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "Back",
    },
  },
  emits: ["oncancel", "onsave"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const ID = props.id ? props.id : uuidv4();
    const Tabbed = toRef(props, "tabbed");
    const Settings = computed(() => store.getters.settings);
    const $ = window.$;
    const publicPath = frontendUrl;
    const fetching = ref(true);
    const fields = {
      uuid: "",
      user_uuid: "",
      customer_name: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      telephone: "",
      fax: "",
      website: "",
      internal_note: "",
      billing_address: "",
      billing_city: "",
      billing_state: "",
      billing_country: "",
      billing_postcode: "",
      same_address: true,
      shipping_address: "",
      shipping_city: "",
      shipping_state: "",
      shipping_country: "",
      shipping_postcode: "",
      shipping_contact_firstname: "",
      shipping_contact_lastname: "",
      shipping_contact_email: "",
      shipping_contact_mobile: "",
      shipping_contact_telephone: "",
      customer_photo: "",
    };
    const Customer = ref({});
    const CUSTOMER = toRef(props, "customer");
    const GetCustomer = async () => {
      Customer.value = {};
      if (props.uuid) {
        const _customer = await store.dispatch(
          "customers/getCustomer",
          props.uuid
        );
        Customer.value = {
          ..._customer,
        };
      } else if (CUSTOMER.value) {
        Customer.value = CUSTOMER.value;
      } else {
        Customer.value = fields;
      }
      fetching.value = false;
    };
    GetCustomer();
    const SaveCustomer = async () => {
      const response = await store.dispatch(
        "customers/updateCustomer",
        Customer.value
      );
      if (response?.status === "success") {
        if (props.uuid) {
          router.push({
            name: "customer-detail",
            params: { uuid: props.uuid },
          });
        } else {
          Customer.value = { ...fields };
          emit("onsave", Customer.value);
        }
      }
    };
    const OnCancel = () => {
      if (props.uuid) {
        router.push({
          name: "customer-detail",
          params: { uuid: props.uuid },
        });
      } else {
        Customer.value = { ...fields };
        emit("oncancel");
      }
    };
    // TAB VIEWS
    const Views = ["Contact", "Billing", "Shipping", "Others"];
    const ViewsIcons = {
      Contact: "fas fa-user-tie",
      Billing: "fas fa-credit-card",
      Shipping: "fas fa-shipping-fast",
      Others: "fas fa-info-circle",
    };
    const ActiveView = ref(Views[0]);
    const IsActiveView = (view) => {
      if (Tabbed.value) {
        return ActiveView.value === view ? true : false;
      }
      return true;
    };
    const Validate = (view) => {
      console.log(view);
      return true;
    };
    const SwitchView = (view) => {
      const newViewIndex = Views.indexOf(view);
      if (ActiveView.value === Views[newViewIndex]) return;
      if (Validate(ActiveView.value)) ActiveView.value = Views[newViewIndex];
    };
    const BillingStates = computed(() => store.state.billing_states);
    const ShippingStates = computed(() => store.state.shipping_states);
    const GetBillingStates = () => {
      store.dispatch("states", {
        country: Customer.value.billing_country,
        state_field: "billing_states",
      });
    };
    const GetShippingStates = () => {
      store.dispatch("states", {
        country: Customer.value.shipping_country,
        state_field: "shipping_states",
      });
    };
    GetBillingStates();
    GetShippingStates();
    onMounted(() => {
      loadScript(publicPath + "/vendor/jquery/jquery-3.6.0.min.js");
      loadScript(publicPath + "/vendor/select2/js/select2.full.min.js")
        .then(() => {
          const $billing_country = $("#billing_country").select2({
            theme: "bootstrap",
          });
          const $billing_state = $("#billing_state").select2({
            theme: "bootstrap",
          });
          $billing_country
            .on("select2:select", function (e) {
              var data = e.params.data;
              Customer.value.billing_country = data.id;
              GetBillingStates();
            })
            .on("select2:unselect", function () {
              store.dispatch("states", {
                billing_country: Customer.value.billing_country,
              });
              $billing_country.val(null).trigger("change");
              $billing_state.val(null).trigger("change");
              Customer.value.billing_country = "";
              Customer.value.billing_state = "";
            });
          $billing_state
            .on("select2:select", function (e) {
              var data = e.params.data;
              Customer.value.billing_state = data.id;
            })
            .on("select2:unselect", function () {
              $billing_state.val(null).trigger("change");
              Customer.value.billing_state = "";
            });

          const $shipping_country = $("#shipping_country").select2({
            theme: "bootstrap",
          });
          const $shipping_state = $("#shipping_state").select2({
            theme: "bootstrap",
          });
          $shipping_country
            .on("select2:select", function (e) {
              var data = e.params.data;
              Customer.value.shipping_country = data.id;
              GetShippingStates();
            })
            .on("select2:unselect", function () {
              store.dispatch("states", {
                shipping_country: Customer.value.shipping_country,
              });
              $shipping_country.val(null).trigger("change");
              $shipping_state.val(null).trigger("change");
              Customer.value.shipping_country = "";
              Customer.value.shipping_state = "";
            });
          $shipping_state
            .on("select2:select", function (e) {
              var data = e.params.data;
              Customer.value.shipping_state = data.id;
            })
            .on("select2:unselect", function () {
              $shipping_state.val(null).trigger("change");
              Customer.value.shipping_state = "";
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
    return {
      store,
      Settings,
      fetching,
      BillingStates,
      ShippingStates,
      GetBillingStates,
      GetShippingStates,
      ID,
      Tabbed,
      Customer,
      SaveCustomer,
      OnCancel,
      Views,
      ViewsIcons,
      IsActiveView,
      ActiveView,
      SwitchView,
      Util,
    };
  },
};
</script>